* {
  /* border: 1px solid purple; */
  /* margin: 1%;
  padding: 1%; */
  box-sizing: border-box;
  background-color: whitesmoke;
}
.uniter {
  /* margin: 1%;
  padding: 1%; */
}
.scam {
  background-image: url("../public/icons/scam_time_freepik.png");
}

.fastFood {
  background-image: url("../public/icons/fast-food_eucalyp.png");
}

.burgerBar {
  /* background-image: url("../public/icons/burger-bar_febrian\ hidayat.png"); */
  background-image: url("../public/icons/menu_andy_horvath.png");
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
}

.button {
  background-color: white;
  width: 100%;
  border: 1px solid gray;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 20vh; */
}

.free {
  height: 50vh;
  font-size: 3rem;
}

.text-box {
  flex: 1; /* Take up all available space */
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Semi-transparent white background */
  padding: 0; /* Add padding for text content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  width: 85%;
  height: 30vh;
  overflow: hidden;
  background-color: white;
  /* margin: 0; */
  /* padding: 0; */
}

.homeSquare {
  /* Other background properties */
  /* background-size: contain; */
  background-position: center;
  background-repeat: no-repeat;
  width: 30%;
  height: 30vh;
  display: flex;
  align-items: end;
  background-size: 35%;
}

.burgerSquare {
  /* Other background properties */
  /* background-size: contain; */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 30vh; */
  display: flex;
  align-items: end;
  background-size: 80%;
}

.homeSquareText {
  text-align: left;
  /* background-color: white; */
  height: fit-content;
  border: 1px solid lightgray;
}

.footer {
  background-color: #1b1e23;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.flexRow {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
}

.adventureHeadline {
  width: 80%;
  font-size: 3rem;
  text-align: right;
  display: flex;
  align-items: flex-end;
}

.quoteHeadline {
  width: 100%;
  font-size: 10vh;
  text-align: right;
}

.adventureP {
  font-size: 5vh;
}

.adventureFlex {
  display: flex;
  flex-direction: row;
}

.adventureSquare {
  background-position: center;
  background-repeat: no-repeat;
  width: 30%;
  height: 30vh;
  display: flex;
  align-items: end;
  background-size: 35%;
}

.slackIcon {
  height: 20vh;
  width: 100%;
}
.contactText {
  font-size: 3rem;
}
.bigFont {
  font-size: 3rem;
}
.datePicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.productText {
  font-size: 3rem;
}
.burger {
  display: none;
}
.app {
  display: flex;
  flex-direction: column;
}
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.shop-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.shopCard {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 95%;
  margin: 1%;
  padding: 1%;
  flex-wrap: wrap;
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.shopCardName {
  background-color: whitesmoke;
  border: 1px solid black;
  width: 25%;
  display: flex;
  justify-content: center;
  padding: 1%;
}
.shopCardButtons {
  display: flex;
  width: 100%;
  background-color: transparent;
}
.heroDiv {
  background-image: url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700617365/New%20Calendar/xmv4xsapm76ifpehel1t.jpg");
  background-size: cover;
  height: 150vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  justify-content: center;
}
.mediabar_ {
  width: 100%;
}
.mediaBar {
  margin: 2%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.media_logos {
  display: flex;
  justify-content: space-around;
}
.medialogoicon {
  height: 20vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10%;

  background-position: center;
}

.b_feature_image {
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.featureB_copywriting {
  display: flex;
  /* height: 100vh; */
}
.b_copy_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.a_copy_container {
  width: 50%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% 0 0 0;
}
.featureA_copywriting {
  background-position: left bottom;
}
.featureAnimation_copywriting {
  background-size: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.media_column {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.team_descript {
  display: flex;
  justify-content: center;
}
.mediapagelogo {
  height: 40vh;
  width: 25%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;

  justify-content: center;
  align-items: center;
}
/* MOBILE */
@media (max-width: 480px) {
  /* style */
  /* * {
    border: 1px solid indianred;
  } */
  .classicProductContainer {
    flex-direction: column;
  }
  .mediapagelogo {
    /* height: 40vh; */
    width: 100%;
    /* background-size: cover; */
  }
  .featureAnimation_copywriting {
    height: 45vh;
  }
  .featureA_copywriting {
    background-position: right center;
  }
  .a_copy_container {
    width: 100%;
  }
  .b_copy_container {
    width: 100%;
    height: auto;
  }

  .featureB_copywriting {
    flex-direction: column;
  }
  .bCopy {
    width: 100%;
    /* height: 30vh; */
  }
  .b_feature_image {
    width: 100%;
    height: 70vh;
  }
  .mediaBar {
    width: 100%;
  }
  .media_logos {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .medialogoicon {
    width: 25%;
    padding: 1%;
    margin: 1%;
    height: 15vh;
  }
  .heroDiv {
    background-image: url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700712063/VERT_hero_y7ufrk.jpg");
    background-position: center center;
  }
  .shopCardButtons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .shopCardName {
    width: 75%;
  }

  .shop-card-container {
    flex-direction: column;
    align-items: center;
  }
  .shopCard {
    /* width: 90%; */
    /* height: 80vh; */
    background-position: center center;
    align-items: center;
  }
  .adventureSquare {
    width: 100%;
    height: 15vh;
    background-size: 40%;
  }
  .adventureFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adventureP {
    font-size: 1rem;
  }
  .adventureHeadline {
    width: 80%;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
  }
  .quoteHeadline {
    font-size: 1rem;
  }
  .free {
    height: 30vh;
    width: 100%;
    font-size: 2rem;
  }
  .slackIcon {
    height: 10vh;
    width: 100%;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .featureAnimation_copywriting {
    height: 60vh;
  }
  .hero {
    height: 15vh;
    width: 200%;
  }
  .homeSquareContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .homeSquare {
    width: 100%;
    height: 15vh;
  }
  .footer {
    background-color: #1b1e23;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .flexRow {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .flex {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .button {
    flex: 0 0 calc(48%);
    flex-direction: column;
    font-size: 1rem;
  }
  .contactText {
    font-size: 1.5rem;
    height: 10vh;
  }
  .bigFont {
    font-size: 1.5rem;
  }
  .datePicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  .productText {
    font-size: 1.15rem;
    height: 20vh;
  }
  .navbar {
    display: none;
  }
  .burger {
    display: flex;
    width: 15%;
  }
  /* .app {
    flex-direction: row;
  } */
  .headlineFont {
    font-size: 1.85rem;
  }
}

/* ESTRA SMALL DEVICE */
@media (min-width: 481px) and (max-width: 767px) {
  /* style */
  /* * {
    border: 1px solid orange;
  } */
  .adventureSquare {
    width: 50%;
    height: 35vh;
  }
  .adventureFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adventureP {
    font-size: 1rem;
  }
  .adventureHeadline {
    width: 80%;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
  }
  .quoteHeadline {
    font-size: 1.25rem;
  }
  .homeSquareContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .homeSquare {
    width: 40%;
    height: 15vh;
  }
  .hero {
    height: 15vh;
  }
  .footer {
    background-color: #1b1e23;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .flexRow {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

/* SMALL TABLETS */
@media (min-width: 768px) and (max-width: 991px) {
  /* style */
  /* * {
    border: 1px solid yellow;
  } */
  .adventureSquare {
    width: 50%;
    height: 35vh;
  }
  .adventureFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adventureP {
    font-size: 1rem;
  }
  .adventureHeadline {
    width: 80%;
    font-size: 2rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
  }
  .quoteHeadline {
    font-size: 2rem;
  }
  .hero {
    height: 18vh;
  }
}

/* LARgE TABLETS/LAPTOPS */
@media (min-width: 992px) and (max-width: 1199px) {
  /* style */
  /* * {
    border: 1px solid green;
  } */
  .adventureSquare {
    width: 50%;
    height: 35vh;
  }
  .adventureFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adventureP {
    font-size: 1rem;
  }
  .adventureHeadline {
    width: 80%;
    font-size: 2rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
  }
  .quoteHeadline {
    font-size: 2rem;
  }
}

/* DESKTOPS */
@media (min-width: 1200px) and (max-width: 1919px) {
  /* style */
  /* * {
    border: 1px solid aqua;
  } */
  .adventureSquare {
    width: 50%;
    height: 35vh;
  }
  .adventureFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adventureP {
    font-size: 1rem;
  }
  .adventureHeadline {
    width: 80%;
    font-size: 2rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
  }
  .quoteHeadline {
    font-size: 2rem;
  }
  .hero {
    height: 33vh;
  }
}

/* EXTRA LARGE SCREENS */
@media (min-width: 1920px) {
  /* style */
  /* * {
    border: 1px solid purple;
  } */
}

.winter {
  background-color: #93e1d8;
}
.spring {
  background-color: #ef767a;
}
.summer {
  background-color: #82d173;
}
.autumn {
  background-color: #eeb868;
}
.fall {
  background-color: #8c4843;
}

.money {
  background-image: url("../public/icons/time-is-money_slidicon.png");
}
.measurement {
  background-image: url("../public/icons/measure_creaticcacreativeagency.png");
}
.mystery {
  background-image: url("../public/icons/magic_prediction_uniconlabs.png");
}
.confusing {
  background-image: url("../public/icons/confuse_time_freepik.png");
}
.preciousResource {
  background-image: url("../public/icons/precious_resource_freepik.png");
}
.frequency {
  background-image: url("../public/icons/frequency_icongeek26.png");
}
.humanConstruction {
  background-image: url("../public/icons/human_construct_madebymadepremium.png");
}
.flatCircle {
  background-image: url("../public/icons/cycle_time_uniconlabs.png");
}
.tool {
  background-image: url("../public/icons/tool_time_uniconlabs.png");
}
.dimension {
  background-image: url("../public/icons/dimensions_good_ware.png");
}
.science {
  background-image: url("../public/icons/vial_clock_smashicons.png");
}
.everything {
  background-image: url("../public/icons/efficiency_noomtah.png");
}
.flying {
  background-image: url("../public/icons/time_flies_smashicons.png");
}
.nature {
  background-image: url("../public/icons/time_nature_NikitaGolubev.png");
}
.ruler {
  background-image: url("../public/icons/prime-time_iconjam.png");
}
.revolution {
  background-image: url("../public/icons/revolution_Parzival’\ 1997.png");
}

/* .anchor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.day {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: solid 2px black;
  width: 12%;
  height: 10vh;
  margin: 0;
  padding: 0;
}
.filled {
  background-color: #5a5a5a;
}
.mark {
  background-color: lightgray;
}
.weekOne {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0;
  background-color: red;
} */
/* * {
  border: 1px solid pink;
  margin: 1%;
  padding: 1%;
  box-sizing: border-box;
}

.border {
  border: 1px solid black;
} */

/*
.flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.flexRow {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forty {
  width: 40%;
}
.column {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
}
.rowToColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.iconParagraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 20vh; 
  margin: 5%; 
  padding: 5%;
}
.icon {
  height: 20vh;
  width: auto;
}
@media screen and (max-width: 1000px) {
  .rowToColumn {
    display: flex;
    flex-direction: column;
  }
  .iconParagraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30vh;
  }
  .icon {
    height: 15vh;
    width: 10%; 
  }
  .flexRow {
    width: 60%;
  }
}

.video {
  width: 100%;
  height: 100vh;
}

.border {
  border: 1px solid black;
}

.one {
  background-color: #82d173;
  color: black;
  text-decoration: none;
  border-radius: 5%;
}

a {
  text-decoration: none;
  text-align: center;
  width: 30%;
  height: auto;
}

a:visited {
  color: gray;
}

a:hover {
  background-color: #1b1e23;
  color: whitesmoke;
}

.two {
  background-color: #f2c14e;
  color: whitesmoke;
}

.three {
  background-color: #f78154;
  color: whitesmoke;
}

.four {
  background-color: #4d9078;
  color: whitesmoke;
}

.five {
  background-color: #b4436c;
  color: whitesmoke;
}

::placeholder {
  color: whitesmoke;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul.a {
  list-style-type: circle;
  list-style-position: outside;
}

.full {
  width: 100%;
}

.half {
  width: 80%;
}

.imghalf {
  width: 50%;
  height: auto;
}

.forty {
  width: 40%;
}

.right-text {
  text-align: end;
}

.left-text {
  text-align: left;
}

.blue {
  color: #0000ee;
}

.third {
  width: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fifth {
  width: 18%;
  display: flex;
  justify-content: center;
}

.eighth {
  width: 8%;
  display: flex;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-row-tight {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  border: 1px solid black;
}

.flex-card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

a .head-button-forward {
  width: 30%;
  height: auto;
}

.head-button-forward {
  filter: invert(100%) sepia(1%) saturate(49%) hue-rotate(206deg)
    brightness(116%) contrast(92%);
  border: none;
}

a .head-button-backward {
  width: 30%;
  height: auto;
}

.head-button-backward {
  filter: invert(100%) sepia(1%) saturate(49%) hue-rotate(206deg)
    brightness(116%) contrast(92%);
  border: none;
  transform: rotate(180deg);
}

a .head-button-home {
  width: 30%;
  height: auto;
}

.head-button-home {
  filter: invert(100%) sepia(1%) saturate(49%) hue-rotate(206deg)
    brightness(116%) contrast(92%);
  border: none;
}

.rotate {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  text-align: center;
}

.big-screen {
  display: flex;
  flex-direction: row;
}

.on {
  background-color: #1b1e23;
  color: whitesmoke;
}

.quote {
  font-size: 2rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footer {
  background-color: #1b1e23;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.foot-note {
  padding: 0;
  margin: 1%;
  border: 1px solid lime; 
  width: auto;
}

.foot-icon {
  height: 5vh;
  filter: invert(100%) sepia(1%) saturate(49%) hue-rotate(206deg)
    brightness(116%) contrast(92%);
}

a:hover .foot-icon {
  background-color: whitesmoke;
}

@media (max-width: 1300px) {
  .third {
    width: 40%;
  }
}

@media (max-width: 1000px) {
  .eighth {
    width: 20%;
  }
  .third {
    width: 100%;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .left {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .imghalf {
    margin: auto;
  }
  .half {
    width: 75%;
    margin: auto;
  }

  a .head-button-home {
    width: 30%;
    height: auto;
  }

  a .head-button-backward {
    width: 30%;
    height: auto;
  }

  a .head-button-forward {
    width: 30%;
    height: auto;
  }

  .flex-head {
    flex-direction: column;
    align-items: center;
  }

  .mobile {
    width: 30%;
  }

  .flex-card-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 653px) {
  .imghalf {
    width: 100%;
  }
  .half {
    width: 80%;
  }
  .flex {
    flex-direction: column;
    justify-content: center;
  }
  .video {
    width: max-content;
    height: 40vh;
  }
  .button-container {
    flex-direction: column;
  }
  .winter {
    width: 100%;
  }
  .spring {
    width: 100%;
  }
  .summer {
    width: 100%;
  }
  .autumn {
    width: 100%;
  }
  .fall {
    width: 100%;
  }
  .one {
    width: 100%;
  }
  .two {
    width: 100%;
  }
  .three {
    width: 100%;
  }
  .four {
    width: 100%;
  }
  .five {
    width: 100%;
  }

  a .head-button-home {
    width: 75%;
    height: auto;
  }

  a .head-button-backward {
    width: 75%;
    height: auto;
  }

  a .head-button-forward {
    width: 75%;
    height: auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
